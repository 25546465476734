import React, { useContext } from "react";

import lang from "../lang/home.json";
import logo from "../images/logospin.svg";
import { Context } from "../context/context";
import { LazyLoadImage } from "react-lazy-load-image-component";
const PageLoading = () => {
  const { dil } = useContext(Context);
  return (
    <div className="w-full min-h-[100vh] text-[24px] gap-3 fon-[700] text-blue-logo  flex items-center justify-center">
      <LazyLoadImage
        src={logo}
        className="h-[30px] animate-spin  object-contain"
        alt=""
      />
      {lang[dil].Loading}...
    </div>
  );
};

export default PageLoading;
